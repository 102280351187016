import React, { useEffect, useRef, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import FileViewer from "react-file-viewer";
import { TailSpin } from "react-loader-spinner";
import { COMMON_SERVICE } from "../../../../api/axios";
import axios from "axios";
import * as XLSX from "xlsx";

function SOPFileViewModalDynamic(props) {
  const { AMDFormData } = props;
  const fileUrl = useRef(null);
  const fileType = useRef(null);
  const [loading, setLoading] = useState(true);
  const [excelData, setExcelData] = useState(null);

  useEffect(() => {
    if (props?.show) {
      if (AMDFormData?.node?.data?.fileName) {
        getStoredSOPFile();
      } else {
        setLoading(false);
      }
    }
  }, [props?.show]);

  const getStoredSOPFile = async () => {
    try {
      const { dispositionid } = props?.taskDetailsRef.current;
      if (dispositionid) {
        const response = await axios.get(
          `${COMMON_SERVICE}File/Open/ARWorkflow`,
          {
            params: {
              Location: `DecisionTree/SOP/${dispositionid}`,
              FileName: `${
                AMDFormData.nodeId
              }.${AMDFormData?.node?.data?.fileName?.split(".")?.pop()}`,
            },
            responseType: "blob",
          }
        );
        const blob = new Blob([response.data], {
          type: response.headers["content-type"],
        });
        const fileExtension = AMDFormData?.node?.data?.fileName
          ?.split(".")
          ?.pop();
        fileType.current = fileExtension;

        if (fileExtension === "xlsx" || fileExtension === "xls" || fileExtension === "csv") {
          const arrayBuffer = await blob.arrayBuffer();
          const workbook = XLSX.read(arrayBuffer, { type: "array" });
          const firstSheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[firstSheetName];
          const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
          setExcelData(jsonData); 
        } else {
          const generatedFileUrl = window.URL.createObjectURL(blob);
          fileUrl.current = generatedFileUrl;
        }
      }
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <React.Fragment>
        <Offcanvas
          show={props.show}
          onHide={() => {
            fileUrl.current = null;
            fileType.current = null;
            props.closeFunction();
          }}
          placement="end"
          className="w-25"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>
              <h6 className="d-inline-block">
                {AMDFormData?.node?.data?.label}
              </h6>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <TailSpin color="#00BFFF" height={80} width={80} />
          </Offcanvas.Body>
        </Offcanvas>
      </React.Fragment>
    );
  }
  if (!fileType.current) {
    return (
      <React.Fragment>
        <Offcanvas
          show={props.show}
          onHide={() => {
            fileUrl.current = null;
            fileType.current = null;
            props.closeFunction();
          }}
          placement="end"
          className="w-25"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>
              <h6 className="d-inline-block">
                {AMDFormData?.node?.data?.label}
              </h6>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <p>Unsupported file type: {AMDFormData?.SOPFile?.type}</p>
          </Offcanvas.Body>
        </Offcanvas>
      </React.Fragment>
    );
  }
  if (fileType.current === "xlsx" || fileType.current === "xls"|| fileType.current === "csv") {
    return (
      <Offcanvas
        show={props.show}
        onHide={() => {
          fileUrl.current = null;
          fileType.current = null;
          props.closeFunction();
        }}
        placement="end"
        className="w-40"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <h6 className="d-inline-block">{AMDFormData?.node?.data?.label}</h6>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <table className="table table-bordered">
            <thead>
              {excelData && excelData[0] && (
                <tr>
                  {excelData[0].map((header, index) => (
                    <th key={index}>{header}</th>
                  ))}
                </tr>
              )}
            </thead>
            <tbody>
              {excelData &&
                excelData.slice(1).map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    {row.map((cell, cellIndex) => (
                      <td key={cellIndex}>{cell}</td>
                    ))}
                  </tr>
                ))}
            </tbody>
          </table>
        </Offcanvas.Body>
      </Offcanvas>
    );
  }
  return (
    <React.Fragment>
      <Offcanvas
        show={props.show}
        onHide={() => {
          fileUrl.current = null;
          fileType.current = null;
          props.closeFunction();
        }}
        placement="end"
        className="w-40"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <h6 className="d-inline-block">{AMDFormData?.node?.data?.label}</h6>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div style={{ height: "100%", overflowY: "auto" }}>
            <div style={{ height: "100%" }}>
              {fileType.current === "html" ? (
                <iframe
                  src={fileUrl.current}
                  title={"SOP File"}
                  style={{ width: "100%", height: "100%" }}
                />
              ) : fileType.current === "mp3" ? (
                <audio controls>
                  <source src={fileUrl.current} type="audio/mpeg" />
                  Your browser does not support the audio element.
                </audio>
              ) : fileType.current === "mp4" ? (
                <video controls style={{ width: "100%", height: "100%" }}>
                  <source src={fileUrl.current} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              ) : (
                <FileViewer
                  fileType={fileType.current}
                  filePath={fileUrl.current}
                  onError={(e) => console.log("Error rendering file", e)}
                />
              )}
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </React.Fragment>
  );
}

export default SOPFileViewModalDynamic;
