import useAuth from "../../hooks/useAuth";
import React from "react";
import EmpDashboard from "./EmpDashboard";
import MasterDashboard from "./MasterDashboard";
import TLDashboard from "./TLDashboard";
const Dashboards = () => {
  const { auth } = useAuth();
  return (
    <React.Fragment>
      {auth.roles[0] === "Employee" ? (
        <EmpDashboard />
      ) : auth.roles[0] === "Master" ? (
        <MasterDashboard />
      ) : (
        auth.roles[0] === "Team Lead" && <TLDashboard />
      )}
    </React.Fragment>
  );
};

export default Dashboards;
