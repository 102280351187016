import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import Sidebar from "./Sidebar";

export default function Main() {
  const offsets = [
    /^\/apps\/file-manager$/,
    /^\/apps\/email$/,
    /^\/apps\/calendar$/,
    /^\/inventory\/manage$/,
    /^\/myTasks\/manage$/,
    /^\/MyTask\/manage$/,
    /^\/decision-tree-creator\/[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/, // Matches UUIDs
    /^\/myTasks\/details$/,
    /^\/placements\/manage$/,
    /^\/taskEngine\/manage$/,
    /^\/master-catalogue$/,
    /^\/system-settings$/,
    /^\/account\/login$/,
    /^\/account\/forgot-password$/,
    /^\/account\/setup-password$/,
  ];
  

  const { pathname } = useLocation();
  const bc = document.body.classList;
  offsets.some(pattern => pattern.test(pathname)) ? bc.add("sidebar-offset") : bc.remove("sidebar-offset");
  bc.remove("sidebar-show");

  window.scrollTo(0, 0);

  return (
    <React.Fragment>
      <Sidebar />
      <Outlet />
    </React.Fragment>
  );
}