import React from "react";
import TaskBucketDashboard from "../View/Reports/TaskDashboard/TaskDashboard";
import DailyProductionDashboard from "../View/Reports/DailyDashboard/DailyProductionDashboard";
import WeeklyProductionDashboard from "../View/Reports/WeeklyDashboard/WeeklyProductionDashboard";
import NonWorkableDashboard from "../View/Reports/WorkableDashboard/NonWorkableDashboard";
import UserWiseReport from "../View/Reports/UserReport/user-report";
import PendingQueries from "../View/PendingQueries/pending-queries";
import Inventory from "../View/Inventory/inventory";
import Placements from "../View/Placements/manage";
import SystemSettings from "../View/Settings/system-setting";
import CreateDecisionTree from "../View/DecisionTreeCreator/CreateDecisionTree";
import DecisionTreeManage from "../View/DecisionTreeCreator/ManageDecisionTree";
import MyTask2 from "../View/MyTask/v2/manage";
import ApexCharts from "../View/Chart/ApexCharts";
import ChartJs from "../View/Chart/ChartJs";
import Dashboards from "../View/Dashboard/Dashboards";

export const MasterProtectedRoutes = [
  { path: "/", element: <Dashboards /> },
  { path: "docs/chart/apex", element: <ApexCharts /> },
  { path: "docs/chart/chartjs", element: <ChartJs /> },
  { path: "dashboard", element: <Dashboards /> },
  { path: "reports/task-dashboard", element: <TaskBucketDashboard /> },
  {
    path: "reports/daily-prod-dashboard",
    element: <DailyProductionDashboard />,
  },
  {
    path: "reports/weekly-prod-dashboard",
    element: <WeeklyProductionDashboard />,
  },
  { path: "reports/workable-dashboard", element: <NonWorkableDashboard /> },
  { path: "reports/user-wise-report", element: <UserWiseReport /> },
  { path: "inventory/manage", element: <Inventory /> },
  { path: "placements/manage", element: <Placements /> },
  { path: "decision-tree-creator/:id", element: <CreateDecisionTree /> },
  { path: "decision-tree-creator/manage", element: <DecisionTreeManage /> },
  { path: "system-settings", element: <SystemSettings /> },
];

export const TLProtectedRoutes = [
  { path: "/", element: <Dashboards /> },
  { path: "docs/chart/apex", element: <ApexCharts /> },
  { path: "docs/chart/chartjs", element: <ChartJs /> },
  { path: "dashboard", element: <Dashboards /> },
  { path: "reports/task-dashboard", element: <TaskBucketDashboard /> },
  {
    path: "reports/daily-prod-dashboard",
    element: <DailyProductionDashboard />,
  },
  {
    path: "reports/weekly-prod-dashboard",
    element: <WeeklyProductionDashboard />,
  },
  { path: "reports/workable-dashboard", element: <NonWorkableDashboard /> },
  { path: "reports/user-wise-report", element: <UserWiseReport /> },
  { path: "pendingQueries/manage", element: <PendingQueries /> },
  { path: "placements/manage", element: <Placements /> },
];

export const EmployeeProtectedRoutes = [
  { path: "/", element: <Dashboards /> },
  { path: "docs/chart/apex", element: <ApexCharts /> },
  { path: "docs/chart/chartjs", element: <ChartJs /> },
  { path: "dashboard", element: <Dashboards /> },
  { path: "reports/task-dashboard", element: <TaskBucketDashboard /> },
  {
    path: "reports/daily-prod-dashboard",
    element: <DailyProductionDashboard />,
  },
  {
    path: "reports/weekly-prod-dashboard",
    element: <WeeklyProductionDashboard />,
  },
  { path: "reports/workable-dashboard", element: <NonWorkableDashboard /> },
  { path: "reports/user-wise-report", element: <UserWiseReport /> },
  { path: "MyTask/manage", element: <MyTask2 /> },
];
