import React, { useEffect, useState } from "react";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import { Form } from "react-bootstrap";
import ToastifyService from "../../../_common/ToastifyService";
import { Button, Offcanvas } from "react-bootstrap";
import { TailSpin } from "react-loader-spinner";

function AMDStatusFormModalDynamic(props) {
  const { show, AMDFormData, amdFormSubmittedData, onSubmit, closeFunction } =
    props;
  const axiosPrivate = useAxiosPrivate();
  const [amdStatusId, setAMDStatusId] = useState(null);
  const [AMDStatusFormList, setAMDStatusFormList] = useState([]);
  const [loading, setLoading] = useState({ isLoading: false, value: "" });
  const [formData, setFormData] = useState({});
  const AMDFormFields = JSON.parse(
    sessionStorage.getItem("amdStatuscommonField")
  );
  useEffect(() => {
    const prefilledData = {};
    if (amdFormSubmittedData && amdFormSubmittedData.data) {
      Object.keys(amdFormSubmittedData.data).forEach((field) => {
        prefilledData[field] = amdFormSubmittedData.data[field];
      });
    }
    setFormData(prefilledData);
  }, [amdFormSubmittedData]);
  useEffect(() => {
    if (show) {
      if (AMDFormData.amdStatus || amdStatusId === null) {
        setAMDStatusId(AMDFormData.amdStatus);
      }
    }
  }, [show]);

  useEffect(() => {
    if (show && amdStatusId) {
      getAMDStatusFormList(amdStatusId);
    }
  }, [amdStatusId]);
  useEffect(() => {
    if (
      AMDStatusFormList &&
      AMDStatusFormList?.length !== 0 &&
      amdFormSubmittedData?.FormMasterIdData &&
      AMDFormData?.nodeId === amdFormSubmittedData?.nodeId
    ) {
      setFormData(amdFormSubmittedData?.FormMasterIdData);
    }
  }, [AMDStatusFormList, AMDFormData, amdFormSubmittedData]);

  useEffect(() => {
    if (AMDStatusFormList && AMDStatusFormList?.length !== 0) {
      // Find the visit field
      const visitField = AMDStatusFormList?.amdFormMasterWithAMDStatus?.find(
        (field) =>
          field.datatypeId === "4e0073a0-2339-4acf-bbd7-6b265bed7f02" &&
          field.formMasterId === "996b1e7a-962d-4a1b-a9bd-903ce40dff02"
      );
      if (visitField) {
        setFormData((prevData) => ({
          ...prevData,
          [visitField.formMasterId]: AMDFormData?.visitId || "",
        }));
      }

      // Find the DateWorkedField
      const DateWorkedField =
        AMDStatusFormList?.amdFormMasterWithAMDStatus?.find(
          (field) =>
            field.datatypeId === "4e0073a0-2339-4acf-bbd7-6b265bed7f03" &&
            field.formMasterId === "996b1e7a-962d-4a1b-a9bd-903ce40dff01"
        );
      if (DateWorkedField) {
        // Get today's date
        const today = new Date();
        const formattedDate = today.toISOString().split("T")[0]; // format as "YYYY-MM-DD"

        setFormData((prevData) => ({
          ...prevData,
          [DateWorkedField.formMasterId]: formattedDate,
        }));
      }
    }
  }, [AMDStatusFormList]);

  const getAMDStatusFormList = async (amdStatusId) => {
    try {
      setLoading({ isLoading: true, value: "getAMDStatusFormList" });
      const AMDStatusFormListData = await axiosPrivate.get(
        `Setting/get-AMD-Status-Details/${amdStatusId}`
      );
      setAMDStatusFormList(AMDStatusFormListData.data);
    } catch (err) {
      setLoading({ isLoading: false, value: "getAMDStatusFormList" });
      console.error(err);
    } finally {
      setLoading({ isLoading: false, value: "getAMDStatusFormList" });
    }
  };
  const handleFormSubmit = (e) => {
    e.preventDefault();
    const submittedData = {};
    let totalFields = 0;
    let fieldsWithValues = 0;
    const excludedFormMasterIds = [
      "996b1e7a-962d-4a1b-a9bd-903ce40dff02",
      "996b1e7a-962d-4a1b-a9bd-903ce40dff01",
    ];
    AMDStatusFormList?.amdFormMasterWithAMDStatus?.forEach((field) => {
      const fieldLabel = field.formLabel;
      if (excludedFormMasterIds.includes(field.formMasterId)) {
        return;
      }
      totalFields++;
      let fieldValue = "";

      if (field.isList) {
        const selectedOptionId = formData[field.formMasterId];
        const selectedOption = AMDFormFields?.find(
          (amdff) => amdff.id === field.listId
        )?.fieldValues?.find((fv) => fv.id === selectedOptionId);
        fieldValue = selectedOption ? selectedOption.value : "";
      } else {
        fieldValue = formData[field.formMasterId] || "";
      }
      if (
        fieldValue !== "" &&
        fieldValue !== null &&
        fieldValue !== undefined
      ) {
        fieldsWithValues++;
      }
      submittedData[fieldLabel] = fieldValue;
    });

    const AMDFormSubmissionData = {
      amdStatus: `${AMDStatusFormList?.tabName}-${AMDStatusFormList?.tabCode}`,
      data: submittedData,
      FormMasterIdData: formData,
      nodeId: AMDFormData?.nodeId,
      totalFields: totalFields,
      fieldsWithValues: fieldsWithValues,
    };

    onSubmit(AMDFormSubmissionData);
    ToastifyService.success("Saved!");
    closeFunction();
    setAMDStatusId(null);
    setAMDStatusFormList([]);
    setFormData({});
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const renderFormField = (field) => {
    const { datatypeId, formLabel, isList, listId, isRequired } = field;
    if (isList) {
      return (
        <Form.Group className="mb-3" controlId={field.formMasterId}>
          <Form.Label>{formLabel}</Form.Label>
          <Form.Select
            required={isRequired}
            name={field.formMasterId}
            value={formData[field.formMasterId] || ""}
            onChange={handleInputChange}
          >
            <option value="">Select an option</option>
            {AMDFormFields?.find(
              (amdff) => amdff.id === listId
            )?.fieldValues?.map((fv) => (
              <option value={fv.id} key={fv.id}>
                {fv.value}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
      );
    }

    switch (datatypeId) {
      case "4e0073a0-2339-4acf-bbd7-6b265bed7f03":
        return (
          <Form.Group className="mb-3" controlId={field.formMasterId}>
            <Form.Label>{formLabel}</Form.Label>
            <Form.Control
              type="date"
              required={isRequired}
              name={field.formMasterId}
              value={formData[field.formMasterId] || ""}
              onChange={handleInputChange}
            />
          </Form.Group>
        );
      case "4e0073a0-2339-4acf-bbd7-6b265bed7f02":
        return (
          <Form.Group className="mb-3" controlId={field.formMasterId}>
            <Form.Label>{formLabel}</Form.Label>
            <Form.Control
              type="number"
              required={isRequired}
              name={field.formMasterId}
              value={formData[field.formMasterId] || ""}
              onChange={handleInputChange}
            />
          </Form.Group>
        );
      case "4e0073a0-2339-4acf-bbd7-6b265bed7f07":
        return (
          <Form.Group className="mb-3" controlId={field.formMasterId}>
            <Form.Label>{formLabel}</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              required={isRequired}
              name={field.formMasterId}
              value={formData[field.formMasterId] || ""}
              onChange={handleInputChange}
            />
          </Form.Group>
        );
      case "4e0073a0-2339-4acf-bbd7-6b265bed7f01":
        return (
          <Form.Group className="mb-3" controlId={field.formMasterId}>
            <Form.Label>{formLabel}</Form.Label>
            <Form.Control
              type="text"
              required={isRequired}
              name={field.formMasterId}
              value={formData[field.formMasterId] || ""}
              onChange={handleInputChange}
            />
          </Form.Group>
        );
      default:
        return null;
    }
  };
  return (
    <React.Fragment>
      <Offcanvas
        show={show}
        onHide={() => {
          closeFunction();
          setAMDStatusId(null);
          setAMDStatusFormList(null);
          setFormData({});
        }}
        placement="end"
        className="w-25"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <h6 className="d-inline-block">AMD Status</h6> :{" "}
            <b>{AMDStatusFormList?.tabName}</b>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Form>
            {loading.isLoading && loading.value === "getAMDStatusFormList" ? (
              <div className="spinner-loader-sidenav">
                <TailSpin color="#0d6efd" height={30} />
              </div>
            ) : (
              <>
                {AMDStatusFormList?.amdFormMasterWithAMDStatus?.map((field) => (
                  <React.Fragment key={field.formMasterId}>
                    {renderFormField(field)}
                  </React.Fragment>
                ))}
              </>
            )}
          </Form>
        </Offcanvas.Body>
        <div className="offcanvas-footer justify-content-start">
          <Button variant="primary" type="submit" onClick={handleFormSubmit}>
            Save
          </Button>
        </div>
      </Offcanvas>
    </React.Fragment>
  );
}

export default AMDStatusFormModalDynamic;
