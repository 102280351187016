import axios from "axios";

const BASE_URL_HOSTED = "https://arworkflow.innovalglobal.com:802/api/";
// const BASE_URL_HOSTED = "https://argateway.cxengine.net/api/";
const BASE_URL_LOCAL = "https://localhost:44387/api/";
const COMMON_SERVICE_LOCAL="https://localhost:44305/api/";
const COMMON_SERVICE_HOSTED="https://arcommonservices.cxengine.net/api/";
const mode = "";
export default axios.create({
  baseURL: mode === "dev" ? BASE_URL_LOCAL : BASE_URL_HOSTED,
});
export const COMMON_SERVICE=mode === "dev" ? COMMON_SERVICE_LOCAL : COMMON_SERVICE_HOSTED;
export const axiosPrivate = axios.create({
  baseURL: mode === "dev" ? BASE_URL_LOCAL : BASE_URL_HOSTED,
  headers: { "Content-Type": "application/json" },
});
