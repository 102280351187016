import React from "react";
import NotFound from "../View/_common/NotFound";
import LoginComponent from "../View/Account/login";
import SignupComponent from "../View/Account/signup";
import ForgotPasswordComponent from "../View/Account/forgot-password";
import SetUpPassword from "../View/Account/setupPassword";

const publicRoutes = [
  { path: "pages/error-404", element: <NotFound /> },
  { path: "account/login", element: <LoginComponent /> },
  { path: "account/signup", element: <SignupComponent /> },
  { path: "account/forgot-password", element: <ForgotPasswordComponent /> },
  { path: "account/setup-password", element: <SetUpPassword /> },
];

export default publicRoutes;