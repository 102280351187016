import React, { useState, useEffect, useRef } from "react";
import { Form, Row, Col, Button, Spinner } from "react-bootstrap";
import Select, { components } from "react-select";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import ToastifyService from "../../_common/ToastifyService";

const CustomDropdownIndicator = ({ loading, ...props }) => (
  <components.DropdownIndicator {...props}>
    {loading ? <Spinner animation="border" size="sm" className="ms-2" /> : null}
  </components.DropdownIndicator>
);
const NodeForm = ({
  selectedNodeData,
  nodeDetailsArr,
  onFormSubmit,
  setDeleteSOPFilesArr,
  deleteSOPFilesArr,
}) => {
  const [formData, setFormData] = useState({
    label: "",
    isRequired: false,
    addStatus: false,
    amdStatus: null,
    SOPFile: null,
    isSOPFile: false,
    IsFileUploaded: false,
    FileName: "",
    UIFileName: null,
  });
  const deleteedSOPId = useRef(null);
  const [AMDStatusList, setAMDStatusList] = useState([]);
  const [loading, setLoading] = useState({ isLoading: false, value: "" });
  const axiosPrivate = useAxiosPrivate();

  useEffect(() => {
    getAMDStatusList();
    deleteedSOPId.current = null;
  }, []);

  const getAMDStatusList = async () => {
    try {
      setLoading({ isLoading: true, value: "getAMDStatusList" });
      const AMDStatusData = await axiosPrivate.get(`Setting/get-AMD-Status`);
      const filteredNodes = AMDStatusData?.data;
      setAMDStatusList(filteredNodes);
    } catch (err) {
      console.error(err);
      setLoading({ isLoading: false, value: "getAMDStatusList" });
    } finally {
      setLoading({ isLoading: false, value: "getAMDStatusList" });
    }
  };

  useEffect(() => {
    if (selectedNodeData && AMDStatusList) {
      const existingNode = nodeDetailsArr.find(
        (node) => node.nodeId === selectedNodeData.id
      );

      if (existingNode) {
        const fileIdentifier = `${existingNode.nodeId}`;

        const isFileInDeleteArr = deleteSOPFilesArr.includes(fileIdentifier);
        setFormData({
          label: existingNode.label || "",
          isRequired: existingNode.isRequired || false,
          addStatus: existingNode.addStatus || false,
          amdStatus: existingNode.amdStatus || null,
          SOPFile: existingNode.SOPFile || null,
          isSOPFile: existingNode.isSOPFile || false,
          IsFileUploaded: existingNode.IsFileUploaded || false,
          FileName: existingNode.FileName || "",
          UIFileName: isFileInDeleteArr ? null : existingNode.FileName || null,
        });
      } else {
        setFormData({
          label: selectedNodeData?.data?.label || "",
          isRequired: false,
          addStatus: false,
          amdStatus: null,
          SOPFile: null,
          isSOPFile: false,
          IsFileUploaded: false,
          FileName: "",
          UIFileName: null,
        });
      }
    }
  }, [selectedNodeData, nodeDetailsArr, AMDStatusList, deleteSOPFilesArr]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
    if (name === "addStatus" && !checked) {
      setFormData((prev) => ({
        ...prev,
        amdStatus: null,
      }));
    } else if (name === "isSOPFile" && !checked) {
      setFormData((prev) => ({
        ...prev,
        SOPFile: null,
        UIFileName: null,
      }));
    }
  };

  const handleDropdownChange = (selectedOption) => {
    setFormData((prev) => ({ ...prev, amdStatus: selectedOption.value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (deleteedSOPId.current !== null) {
      setDeleteSOPFilesArr((prevArr) => {
        if (!prevArr.includes(selectedNodeData.id)) {
          return [...prevArr, selectedNodeData.id];
        }
        return prevArr;
      });
    }
    const getBaseName = (fileName) => {
      return fileName.substring(0, fileName.lastIndexOf(".")) || fileName;
    };
    const formDataToSubmit = new FormData();
    formDataToSubmit.append("label", formData.label);
    formDataToSubmit.append("isRequired", formData.isRequired);
    formDataToSubmit.append("addStatus", formData.addStatus);
    formDataToSubmit.append("isSOPFile", formData.isSOPFile);
    formDataToSubmit.append("IsFileUploaded", formData.IsFileUploaded);
    const baseFileName = getBaseName(
      formData.FileName
        ? formData.FileName
        : formData.SOPFile instanceof File
        ? formData.SOPFile.name
        : ""
    );
    const fileExtension = formData.FileName
      ? formData.FileName?.split(".")?.pop()
      : getFileExtension(
          formData.SOPFile instanceof File ? formData.SOPFile.type : ""
        );
    const updatedFileName = `${baseFileName}.${fileExtension}`;

    formDataToSubmit.append("FileName", updatedFileName);

    formDataToSubmit.append(
      "UIFileName",
      formData.SOPFile instanceof File
        ? formData.SOPFile.name
        : formData.UIFileName
        ? formData.UIFileName
        : ""
    );

    formDataToSubmit.append("amdStatus", formData.amdStatus);
    formDataToSubmit.append("SOPFile", formData.SOPFile);

    onFormSubmit(selectedNodeData?.id, formDataToSubmit);
    ToastifyService.success("Saved!");
  };
  const getFileExtension = (mimeType) => {
    const typeMap = {
      "application/pdf": "pdf",
      "image/jpeg": "jpg",
      "image/png": "png",
      "image/svg+xml": "svg",
      "text/html": "html",
      "audio/mpeg": "mp3",
      "video/mp4": "mp4",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        "docx",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.document":
        "xlsx",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
        "xlsx",
      "text/csv": "csv",
      "image/jfif": "jfif",
    };
    return typeMap[mimeType] || "";
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    setFormData((prev) => ({
      ...prev,
      SOPFile: file,
    }));
  };
  const removeFile = () => {
    deleteedSOPId.current = selectedNodeData.id;
    if (formData.SOPFile) {
      setFormData((prev) => ({
        ...prev,
        SOPFile: null,
        UIFileName: null,
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        UIFileName: null,
      }));
    }
  };
  return (
    <React.Fragment>
      <form onSubmit={handleSubmit}>
        <div>
          <h4 className="main-title mb-3">Node Details</h4>
          <div className="mb-3">
            <Form.Label>Label Name</Form.Label>
            <Form.Control
              name="label"
              placeholder="Enter Label Name"
              value={formData.label}
              onChange={handleChange}
              disabled
            />
          </div>
          <div className="mb-3">
            <Form.Check
              type="checkbox"
              name="isRequired"
              label="Is Required"
              checked={!!formData.isRequired}
              onChange={handleChange}
            />
          </div>
          {/* <div className="mb-3">
          <Form.Label>Specific Validation</Form.Label>
          <Form.Control
            name="specificValidation"
            placeholder="Enter regex for specific validation"
            value={formData.specificValidation}
            onChange={handleChange}
          />
        </div> */}
          <div className="mb-3">
            <Form.Check
              type="checkbox"
              name="isSOPFile"
              label="Add SOP File"
              checked={!!formData.isSOPFile}
              onChange={handleChange}
            />
          </div>
          {Boolean(formData.isSOPFile) === true && (
            <div className="mb-3">
              <div className="d-flex gap-2 mt-3 mt-md-0">
                <label
                  htmlFor="upload-input"
                  className="btn btn-primary d-flex align-items-center gap-1"
                >
                  <Form.Check
                    id="upload-input"
                    type="file"
                    accept="*/*"
                    style={{ display: "none" }}
                    name="SOPFile"
                    onChange={(e) => handleFileUpload(e)}
                  />
                  <i className="ri-upload-line fs-16 lh-1"></i>
                  <span className="d-sm-inline">Upload SOP File</span>
                </label>
              </div>
              {(formData.SOPFile !== null || formData?.UIFileName) && (
                <div className="mt-2">
                  <span>{formData?.SOPFile?.name || formData?.UIFileName}</span>
                  <Button
                    variant="link"
                    className="text-danger"
                    onClick={() => removeFile()}
                  >
                    <i className="ri-close-line"></i>
                  </Button>
                </div>
              )}
            </div>
          )}
          <div className="mb-3">
            <Form.Check
              type="checkbox"
              name="addStatus"
              label="Add Status"
              checked={!!formData.addStatus}
              onChange={handleChange}
            />
          </div>

          {Boolean(formData.addStatus) === true && (
            <div className="mb-3">
              <Row className="mb-4 align-items-center">
                <Col md={12}>
                  <Form.Label>AMD Status</Form.Label>
                </Col>
                <Col>
                  <Select
                    isSearchable={true}
                    onChange={handleDropdownChange}
                    options={AMDStatusList?.map((option) => ({
                      value: option.id,
                      label: option.tabName,
                    }))}
                    value={AMDStatusList?.map((option) => ({
                      value: option.id,
                      label: option.tabName,
                    }))?.find((amdsts) => amdsts.value === formData.amdStatus)}
                    isDisabled={
                      loading.isLoading && loading.value === "getAMDStatusList"
                    }
                    components={{
                      DropdownIndicator: (props) => (
                        <CustomDropdownIndicator
                          {...props}
                          loading={
                            loading.isLoading &&
                            loading.value === "getAMDStatusList"
                          }
                        />
                      ),
                    }}
                  />
                </Col>
              </Row>
            </div>
          )}
        </div>
        <hr />
        <Button variant="primary" type="submit">
          Save
        </Button>
      </form>
    </React.Fragment>
  );
};

export default NodeForm;
