import React, { useEffect, useState } from "react";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import useAuth from "../../../hooks/useAuth";
import Header from "../../../layouts/Header";
import { Card, Table } from "react-bootstrap";
import ShimmerLoader from "../../_common/ShimmerLoader";

function MasterDailyProductionDashboard() {
  const axiosPrivate = useAxiosPrivate();
  const [DailyDashobard, setDailyDashobardData] = useState([]);
  const { auth } = useAuth();
  useEffect(() => {
    getDailyDashobardData();
  }, []);
  const [loading, setLoading] = useState({
    isLoading: false,
    value: "",
  });
  const getDailyDashobardData = async () => {
    try {
      setLoading({ isLoading: true, value: "getDailyDashobardData" });
      const DailyDashobardData = await axiosPrivate.get(
        `Dashboard/daily-production`
      );
      setDailyDashobardData(DailyDashobardData?.data?.result);
    } catch (err) {
      console.error(err);
      setLoading({ isLoading: false, value: "getDailyDashobardData" });
    } finally {
      setLoading({ isLoading: false, value: "getDailyDashobardData" });
    }
  };
  return (
    <React.Fragment>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <h4 className="main-title mb-0">
              Master Daily Production Dashboard
            </h4>
          </div>
        </div>

        <Card className="card-one">
          {loading.isLoading && loading.value === "getSaagMasterList" ? (
            <ShimmerLoader />
          ) : (
            <Card.Body>
              <Table responsive>
                <thead>
                  <tr>
                    <th>User Name (Age days in system)</th>
                    <th>TL</th>
                    <th>Vendor Name</th>
                    <th>Date</th>
                    <th># Of claims worked</th>
                  </tr>
                </thead>

                <tbody>
                  {DailyDashobard.map((daily, index) => (
                    <tr key={index}>
                      <td>
                        {daily?.username}
                        <span> ({daily.age}) </span>
                      </td>
                      <td>{daily?.teamLeadName}</td>
                      <td>{daily?.vendorName}</td>
                      <td>{new Date().toLocaleString()}</td>
                      <td>{daily?.claimsWorkedCount}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card.Body>
          )}
        </Card>
      </div>
    </React.Fragment>
  );
}

export default MasterDailyProductionDashboard;
