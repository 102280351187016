import React from "react";
import useAuth from "../../../hooks/useAuth";
import EmployeeTaskDashboard from "./EmployeeTaskDashboard";
import MasterTaskDashboard from "./MasterTaskDashboard";
import TLTaskDashboard from "./TLTaskDashboard";

export default function TaskBucketDashboard() {
  const { auth } = useAuth();
  return (
    <React.Fragment>
      {auth.roles[0] === "Employee" ? (
        <EmployeeTaskDashboard />
      ) : auth.roles[0] === "Master" ? (
        <MasterTaskDashboard />
      ) : (
        auth.roles[0] === "Team Lead" && <TLTaskDashboard />
      )}
    </React.Fragment>
  );
}
