import React, { useEffect, useRef, useState } from "react";
import { Table, Button, Modal, Form, Image } from "react-bootstrap";
import { axiosPrivate, COMMON_SERVICE } from "../../../api/axios";
import useAuth from "../../../hooks/useAuth";
import { Link } from "react-router-dom";
import ToastifyService from "../../_common/ToastifyService";
import LoadingSpinner from "../../_common/LoadingSpinner";
import Commonfield from "../../../infrastructure/core/Commonfield";
import axios from "axios";

export default function DocumentUpload(props) {
  const { auth } = useAuth();
  const [documentList, setDocumentList] = useState([]);
  const [documentType, setDocumentType] = useState("");
  const [file, setFile] = useState(null);
  const [imageBase64, setImageBase64] = useState("");
  const [croppedImage, setCroppedImage] = useState("");
  const [isSelecting, setIsSelecting] = useState(false);
  const [selection, setSelection] = useState({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  });
  const [scaleFactor, setScaleFactor] = useState({ x: 1, y: 1 });
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  // const [documentFormat, setDocumentFormat] = useState(
  //   Commonfield.defaultValue
  // );
  const [loading, setLoading] = useState({
    isLoading: false,
    value: "",
  });
  const caseDocId = useRef(Commonfield.defaultValue);
  const [pages, setPages] = useState("");
  const taskActionSts = JSON.parse(sessionStorage.getItem("commonField"))?.find(
    (wqty) => wqty.typeGroupCode === "DOCTYP"
  )?.fieldValues;

  useEffect(() => {
    setDocumentList(props?.taskDetails?.casedocumentuploads);
    caseDocId.current = Commonfield.defaultValue;
  }, [props?.taskDetails]);
  const handleDeleteFile = async (id, fileData) => {
    try {
      setLoading({
        isLoading: true,
        value: `Delete-${id}`,
      });
      const fileNames = [
        `${fileData.id}.${fileData.fileName.split(".").pop()}`,
      ];
      const location = `CaseDocs/${props?.taskDetails?.lims_casefile_id}`;
      if (!location) {
        throw new Error("Location is invalid or missing.");
      }
      const formData = new FormData();
      formData.append("Location", location);
      formData.append("FileNames", JSON.stringify(fileNames));
      await axios.post(`${COMMON_SERVICE}File/Delete/ARWorkflow`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      const response = await axiosPrivate.post(
        `Task/delete-case-doc/${id}/${props?.taskDetails?.id}`
      );
      setDocumentList(response?.data?.result);
      caseDocId.current = response?.data?.result?.generatedId;
      ToastifyService.success("Documents Deleted successfully");
    } catch (error) {
      console.error("Error while deleting the files:", error);
      ToastifyService.error("Failed to delete the documents");
    } finally {
      setLoading({
        isLoading: false,
        value: `Delete-${id}`,
      });
    }
  };
  const handleDownloadFile = async (fileData) => {
    try {
      const extension = fileData.fileName.split(".").pop();
      const data = new FormData();
      data.append(
        "Location",
        `CaseDocs/${props?.taskDetails?.lims_casefile_id}`
      );
      data.append("FileName", `${fileData.id}.${extension}`);
      const response = await axios.post(
        `${COMMON_SERVICE}File/Download/ARWorkflow`,
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          responseType: "blob",
        }
      );
      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.download = fileData.fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(downloadUrl);
      ToastifyService.success("Document downloaded successfully");
    } catch (error) {
      console.log(error);
    }
  };
  const handleOpenFile = async (fileData) => {
    try {
      const extension = fileData.fileName.split(".").pop();
      const response = await axios.get(
        `${COMMON_SERVICE}File/Open/ARWorkflow`,
        {
          params: {
            Location: `CaseDocs/${props?.taskDetails?.lims_casefile_id}`,
            FileName: `${fileData.id}.${extension}`,
          },
          responseType: "blob",
        }
      );
      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });
      const fileUrl = window.URL.createObjectURL(blob);
      window.open(fileUrl, "_blank");
      setTimeout(() => window.URL.revokeObjectURL(fileUrl), 60000);
    } catch (error) {
      console.error("Error opening file:", error);
      ToastifyService.error("Failed to open the document");
    }
  };
  const handleFileChange = (e) => {
    setCroppedImage("");
    setImageBase64("");
    setFile(e.target.files[0]);
  };
  const handleUploadDoc = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("FileName", file.name);
    formData.append("File", file);
    formData.append("CaseId", props?.showUploadDocModal?.caseId);
    formData.append("VisitId", props?.showUploadDocModal?.visitId);
    formData.append("DocumentTypeId", documentType);
    // formData.append("DocumentFormatId", documentFormat);
    formData.append("Pages", String(pages));
    formData.append("UploadedBy", auth.id);
    try {
      setLoading({
        isLoading: true,
        value: "UploadDoc",
      });
      const caseDocResponse = await axiosPrivate.post(
        "Task/upload-case-doc",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (!caseDocResponse?.data?.result?.generatedId) {
        throw new Error("Document upload failed to generate ID.");
      }
      const generatedId = caseDocResponse?.data?.result?.generatedId;
      const fileExtension = file.name.split(".").pop();
      const uploadFormData = new FormData();
      uploadFormData.append(
        "Location",
        `CaseDocs/${props?.taskDetails?.lims_casefile_id}`
      );
      uploadFormData.append("Files", file);
      uploadFormData.append("FileNames", `${generatedId}.${fileExtension}`);
      const uploadResponse = await axios.post(
        `${COMMON_SERVICE}File/Upload/ARWorkflow`,
        uploadFormData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (
        !uploadResponse?.data?.uploadedFiles ||
        uploadResponse?.data?.uploadedFiles === 0
      ) {
        throw new Error("File upload to ARWorkflow failed.");
      }
      setPages("");
      setDocumentList(caseDocResponse?.data?.result?.caseDocuments);
      closeModal();
      ToastifyService.success("Document uploaded and saved successfully");
    } catch (error) {
      console.error(error);
      ToastifyService.error("Oops! Something Went Wrong");
      setPages("");
    } finally {
      setLoading({
        isLoading: false,
        value: "UploadDoc",
      });
    }
  };
  const closeModal = () => {
    props?.setShowUploadDocModal(false);
    setDocumentType("");
    // setDocumentFormat("");
  };
  const captureScreenshot = async () => {
    try {
      const stream = await navigator.mediaDevices.getDisplayMedia({
        video: true,
      });
      const video = videoRef.current;
      video.srcObject = stream;
      video.onloadedmetadata = () => {
        video.play();
        video.onplaying = () => {
          const canvas = canvasRef.current;
          const ctx = canvas.getContext("2d");
          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;
          ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
          stream.getTracks().forEach((track) => track.stop());
          const base64 = canvas.toDataURL("image/png");
          setImageBase64(base64);
        };
      };
    } catch (error) {
      console.error("Error capturing screenshot:", error);
    }
  };
  const handleMouseDown = (e) => {
    setIsSelecting(true);
    setSelection({
      x: e.nativeEvent.offsetX,
      y: e.nativeEvent.offsetY,
      width: 0,
      height: 0,
    });
  };
  const handleMouseMove = (e) => {
    if (!isSelecting) return;
    setSelection((prev) => ({
      ...prev,
      width: e.nativeEvent.offsetX - prev.x,
      height: e.nativeEvent.offsetY - prev.y,
    }));
  };
  const handleMouseUp = () => {
    setIsSelecting(false);
    cropSelectedRegion();
  };
  const cropSelectedRegion = () => {
    const canvas = canvasRef.current;
    const x =
      Math.min(selection.x, selection.x + selection.width) * scaleFactor.x;
    const y =
      Math.min(selection.y, selection.y + selection.height) * scaleFactor.y;
    const width = Math.abs(selection.width) * scaleFactor.x;
    const height = Math.abs(selection.height) * scaleFactor.y;

    console.log("Cropping Region:", { x, y, width, height }); // Debugging
    const croppedCanvas = document.createElement("canvas");
    croppedCanvas.width = width;
    croppedCanvas.height = height;
    const croppedCtx = croppedCanvas.getContext("2d");
    croppedCtx.drawImage(
      canvas,
      x,
      y,
      width,
      height,
      0,
      0,
      width,
      height 
    );

    // Convert the cropped canvas to a base64 image
    const base64 = croppedCanvas.toDataURL("image/png");
    setCroppedImage(base64);
  };
  const handleImageLoad = (e) => {
    const img = e.target;
    const scaleX = img.naturalWidth / img.width;
    const scaleY = img.naturalHeight / img.height;
    setScaleFactor({ x: scaleX, y: scaleY });
  };
  return (
    <React.Fragment>
      <div className="d-md-flex align-items-center justify-content-between mb-4">
        <div>
          <h4 className="fs-16 main-title mb-0">Uploaded Documents</h4>
        </div>
      </div>

      <div className="table-responsive document-upload-table">
        <Table className="mb-0">
          <thead>
            <tr>
              <th>File Name</th>
              <th>Document Type</th>
              <th>Pages</th>
              <th>Visit #</th>
              <th>Uploaded On/By</th>
              <th>Action</th>
            </tr>
          </thead>

          <tbody>
            {documentList?.map((item, index) => (
              <tr key={index}>
                <td>
                  <Link
                    className="d-flex align-items-center"
                    onClick={() => handleOpenFile(item)}
                  >
                    <Image src={item.icon} className="wt-16" />
                    <h5 className="fs-14 ms-2">{item.fileName}</h5>
                  </Link>
                </td>
                <td>{item.documentTypeName}</td>
                <td>{item.pages}</td>
                <td>{item.visitId}</td>
                <td>
                  <h5 className="fs-14 mb-1">
                    {new Date(item.modifiedOn).toLocaleString()}
                  </h5>
                  <h6 className="fs-12">{item.modifiedByName}</h6>
                </td>
                <td className="w-0">
                  <div className="d-flex justify-content-end">
                    <Button
                      variant="outline-dark"
                      type="button"
                      className="btn-icon me-2"
                      onClick={() => handleDownloadFile(item)}
                    >
                      <i className="ri-download-line"></i>
                    </Button>
                    <Button
                      variant="danger"
                      type="button"
                      className="btn-icon"
                      disabled={
                        loading.isLoading &&
                        loading?.value === `Delete-${item.id}`
                      }
                      onClick={() => handleDeleteFile(item.id, item)}
                    >
                      {loading.isLoading &&
                      loading?.value === `Delete-${item.id}` ? (
                        <LoadingSpinner
                          color="#ffffff"
                          size={20}
                          type={"TailSpin"}
                        />
                      ) : (
                        <i className="ri-delete-bin-line"></i>
                      )}
                    </Button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <Modal
        centered
        show={props?.showUploadDocModal}
        onHide={() => closeModal()}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title className="fs-14">Upload Document</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <div className="mb-3">
            <Form.Label>Document Format</Form.Label>
            <Form.Select
              value={documentFormat}
              onChange={(e) => {
                setDocumentFormat(e.target.value);
              }}
            >
              <option value={Commonfield.defaultValue} disabled selected>
                Choose Document Format
              </option>
              {DOCFormat?.map((item, index) => (
                <option key={index} value={item.id}>
                  {item?.value}
                </option>
              ))}
            </Form.Select>
          </div> */}
          {/* {documentFormat === Commonfield.documentType.jpeg && ( */}
          <div className="d-flex">
            <div className="mb-3">
              <Form.Label>Choose a File</Form.Label>
              <Form.Control
                type="file"
                onChange={handleFileChange}
                // accept={
                //   documentFormat === "b163f695-4e3e-4aba-bdb5-946432b83a22"
                //     ? "application/pdf"
                //     : "image/jpeg"
                // }
              />
            </div>
            <div className="mb-3 ms-3">
              <Form.Label>Take a screenshot</Form.Label>
              {/* <ScreenCapture onEndCapture={handleScreenCapture}>
                      {({ onStartCapture }) => ( */}
              {/* <Button className="" onClick={() => setTimeout(openSnippingTool,5000)}>
                          Capture
                        </Button> */}
              {/* )}
                    </ScreenCapture> */}

              <header className="App-header">
                <Button onClick={captureScreenshot} varient="primary" className="d-flex align-items-center" size="sm">
                  <i className="fs-18 ri-screenshot-2-line me-2"></i>
                  <span className="fs-14">Capture Screenshot</span>
                </Button>
                <div style={{ position: "relative", marginTop: "20px" }}>
                  {imageBase64 && (
                    <img
                      src={imageBase64}
                      alt="Screenshot"
                      style={{
                        maxWidth: "100%",
                        height: "auto",
                        cursor: "crosshair",
                      }}
                      onMouseDown={handleMouseDown}
                      onMouseMove={handleMouseMove}
                      onMouseUp={handleMouseUp}
                      onLoad={handleImageLoad}
                    />
                  )}
                  {isSelecting && (
                    <div
                      style={{
                        position: "absolute",
                        left: selection.x,
                        top: selection.y,
                        width: selection.width,
                        height: selection.height,
                        border: "2px solid blue",
                        backgroundColor: "rgba(0, 0, 255, 0.2)",
                      }}
                    />
                  )}
                </div>
                {croppedImage && (
                  <div>
                    <h2>Cropped Image:</h2>
                    <img
                      src={croppedImage}
                      alt="Cropped Screenshot"
                      style={{ maxWidth: "100%", height: "auto" }}
                    />
                  </div>
                )}
              </header>
              <video ref={videoRef} style={{ display: "none" }} />
              <canvas ref={canvasRef} style={{ display: "none" }} />
            </div>
            {/* <button
                    onClick={openImageInNewTab}
                    style={{ marginTop: "10px" }}
                  >
                    Open Image in New Tab
                  </button> */}
          </div>
          {/* // )} */}

          {/* {documentFormat !== Commonfield.defaultValue && (
            <> */}
          <div className="mb-3 w-50">
            <Form.Label>Document Type</Form.Label>
            <Form.Select onChange={(e) => setDocumentType(e.target.value)}>
              <option value={Commonfield.defaultValue}>
                {" "}
                Select an option
              </option>

              {taskActionSts && taskActionSts.length > 0 ? (
                taskActionSts.map((item, index) => (
                  <option key={index} value={item?.id}>
                    {item?.value}
                  </option>
                ))
              ) : (
                <option selected> No options available</option>
              )}
            </Form.Select>
          </div>

          <div className="mb-3 w-50">
            <Form.Label>Pages</Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter Pages"
              value={pages}
              onChange={(e) => setPages(e.target.value)}
            />
          </div>
          {/* </>
          )} */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => closeModal()}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={handleUploadDoc}
            disabled={loading.isLoading && loading?.value === "UploadDoc"}
          >
            {loading.isLoading && loading?.value === "UploadDoc" ? (
              <LoadingSpinner color="#ffffff" size={20} type={"TailSpin"} />
            ) : (
              "Upload"
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}
