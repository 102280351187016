import React from "react";
import { Offcanvas, Card } from "react-bootstrap";
import { format } from "date-fns";

export default function CaseDetailsLogs(props) {
  return (
    <React.Fragment>
      <Offcanvas
        show={props.show}
        onHide={props.closeFunction}
        placement="end"
        className="w-30"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="fs-16 text-dark">
            Change Log
          </Offcanvas.Title>
        </Offcanvas.Header>

        <Offcanvas.Body>
          <div className="activity-log-timeline">
            <ul className="activity-group">
              {props?.caseLog?.map((item, index) => (
                <li className={`activity-item `} key={index}>
                  <h5 className="fs-14 mb-1">
                    {item.createdByName} ({item.logSummary}){" "}
                  </h5>
                  <h6 className="text-secondary fs-xs ms-auto">
                    {format(new Date(item.createdOn), "dd MMM yyyy hh:mm a")}
                  </h6>
                </li>
              ))}
            </ul>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </React.Fragment>
  );
}
