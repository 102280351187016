import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import FileViewer from "react-file-viewer";
import { TailSpin } from "react-loader-spinner";
import * as XLSX from "xlsx";
import { COMMON_SERVICE } from "../../../api/axios";
import { useParams } from "react-router-dom";
import { useMemo } from "react";

const SOPFileViewModal = (props) => {
  const { AMDFormData } = props;
  const fileUrlRef = useRef(null);
  const fileTypeRef = useRef(null); 
  const [loadingStateVal, setLoadingState] = useState(true);

  const [excelData, setExcelData] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    setExcelData([]);
    setLoadingState(true);
    if (props?.show) {
      if (AMDFormData?.SOPFile) {
        const file = AMDFormData.SOPFile;
        const blob = new Blob([file], { type: file.type });
        const url = URL.createObjectURL(blob);
        fileUrlRef.current = url;
        fileTypeRef.current = getFileType(file.type);
        if (
          file.type.includes("sheet") ||
          file.type.includes("excel") ||
          file.type.includes("csv")
        ) {
          readExcelOrCSVFile(blob, file.type);
        } else {
          setLoadingState(false);
        }
        return () => {
          if (fileUrlRef.current) {
            URL.revokeObjectURL(fileUrlRef.current);
          }
        };
      } else if (AMDFormData.FileName) {
        getStoredSOPFile();
      } else {
        setLoadingState(false);
      }
    }
  }, [props?.show]);

  const getStoredSOPFile = async () => {
    try {
      const response = await axios.get(
        `${COMMON_SERVICE}File/Open/ARWorkflow`,
        {
          params: {
            Location: `DecisionTree/SOP/${id}`,
            FileName: `${AMDFormData.nodeId}.${AMDFormData?.FileName?.split(
              "."
            )?.pop()}`,
          },
          responseType: "blob",
        }
      );
      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });
      const fileExtension = AMDFormData?.FileName?.split(".")?.pop();
      fileTypeRef.current = fileExtension;
      if (
        fileExtension === "xlsx" ||
        fileExtension === "xls" ||
        fileExtension === "csv"
      ) {
        const arrayBuffer = await blob.arrayBuffer();
        const workbook = XLSX.read(arrayBuffer, { type: "array" });
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        setExcelData(jsonData);
      } else {
        const generatedFileUrl = window.URL.createObjectURL(blob);
        fileUrlRef.current = generatedFileUrl;
      }
      setLoadingState(false);
    } catch (error) {
      setLoadingState(false);
    }
  };

  const readExcelOrCSVFile = (blob, fileType) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      if (fileType.includes("csv")) {
        const textData = e.target.result;
        const csvData = textData.split("\n").map((row) => row.split(","));
        setExcelData(csvData);
      } else {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        setExcelData(jsonData);
      }
      setLoadingState(false);
    };
    if (fileType.includes("csv")) {
      reader.readAsText(blob);
    } else {
      reader.readAsArrayBuffer(blob);
    }
  };

  const getFileType = (mimeType) => {
    const typeMap = {
      "application/pdf": "pdf",
      "image/jpeg": "jpg",
      "image/png": "png",
      "image/svg+xml": "svg",
      "text/html": "html",
      "audio/mpeg": "mp3",
      "video/mp4": "mp4",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        "docx",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.document":
        "xlsx",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
        "xlsx",
      "text/csv": "csv",
    };
    return typeMap[mimeType] || null;
  };

  const isExcelFile = useMemo(
    () =>
      fileTypeRef.current === "xlsx" ||
      fileTypeRef.current === "xls" ||
      fileTypeRef.current === "csv",
    [fileTypeRef.current]
  );
  const isUnsupportedFile = useMemo(
    () => !fileTypeRef.current,
    [fileTypeRef.current]
  );
  const loadingState = useMemo(() => loadingStateVal, [loadingStateVal]);
  if (loadingState) {
    return (
      <React.Fragment>
        <Offcanvas
          show={props.show}
          onHide={() => {
            fileUrlRef.current = null;
            fileTypeRef.current = null;
            setLoadingState(true);
            props.closeFunction();
          }}
          placement="end"
          className="w-25"
        >
          <Offcanvas.Header
            closeButton
            onClick={() => {
              fileUrlRef.current = null;
              fileTypeRef.current = null;
              setLoadingState(true);
              props.closeFunction();
            }}
          >
            <Offcanvas.Title>
              <h6 className="d-inline-block">
                {AMDFormData?.node?.data?.label}
              </h6>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <TailSpin color="#00BFFF" height={80} width={80} />
          </Offcanvas.Body>
        </Offcanvas>
      </React.Fragment>
    );
  }

  if (isUnsupportedFile) {
    return (
      <React.Fragment>
        <Offcanvas
          show={props.show}
          onHide={() => {
            fileUrlRef.current = null;
            fileTypeRef.current = null;
            setLoadingState(true);
            props.closeFunction();
          }}
          placement="end"
          className="w-25"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>
              <h6 className="d-inline-block">
                {AMDFormData?.node?.data?.label}
              </h6>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <p>Unsupported file type: {AMDFormData.SOPFile?.type}</p>
          </Offcanvas.Body>
        </Offcanvas>
      </React.Fragment>
    );
  }

  if (isExcelFile) {
    return (
      <Offcanvas
        show={props.show}
        onHide={() => {
          fileUrlRef.current = null;
          fileTypeRef.current = null;
          setLoadingState(true);
          props.closeFunction();
        }}
        placement="end"
        className="w-40"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <h6 className="d-inline-block">{AMDFormData?.node?.data?.label}</h6>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <table className="table table-bordered">
            <thead>
              {excelData && excelData[0] && (
                <tr>
                  {excelData[0].map((header, index) => (
                    <th key={index}>{header}</th>
                  ))}
                </tr>
              )}
            </thead>
            <tbody>
              {excelData &&
                excelData.slice(1).map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    {row.map((cell, cellIndex) => (
                      <td key={cellIndex}>{cell}</td>
                    ))}
                  </tr>
                ))}
            </tbody>
          </table>
        </Offcanvas.Body>
      </Offcanvas>
    );
  }

  return (
    <React.Fragment>
      <Offcanvas
        show={props.show}
        onHide={() => {
          fileUrlRef.current = null;
          fileTypeRef.current = null;
          setLoadingState(true);
          props.closeFunction();
        }}
        placement="end"
        className="w-40"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <h6 className="d-inline-block">{AMDFormData?.node?.data?.label}</h6>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div style={{ height: "100%", overflowY: "auto" }}>
            {excelData.length > 0 ? (
              <table className="table table-striped">
                <thead>
                  <tr>
                    {excelData[0].map((header, index) => (
                      <th key={index}>{header}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {excelData.slice(1).map((row, index) => (
                    <tr key={index}>
                      {row.map((cell, i) => (
                        <td key={i}>{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <FileViewer
                fileType={fileTypeRef.current}
                filePath={fileUrlRef.current}
              />
            )}
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </React.Fragment>
  );
};

export default SOPFileViewModal;
