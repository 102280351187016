import React, { useEffect, useState } from 'react'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import Header from '../../../layouts/Header';
import { Card, Table } from 'react-bootstrap';

function MasterNonWorkableDashboard() {
    const axiosPrivate = useAxiosPrivate();
    const [nonWorkableDashobard, setnonWorkableDashobard] = useState([]);
    useEffect(() => {
      getNonWorkableDashobard();
    }, []);
    const [loading, setLoading] = useState({
      isLoading: false,
      value: "",
    });
    const getNonWorkableDashobard = async () => {
      try {
        setLoading({ isLoading: true, value: "getNonWorkableDashobard" });
        const weeklyDashobardData = await axiosPrivate.get(
          `Dashboard/non-workable`
        );
        setnonWorkableDashobard(weeklyDashobardData?.data?.result);
      } catch (err) {
        console.error(err);
        setLoading({ isLoading: false, value: "getDailyDashobardData" });
      } finally {
        setLoading({ isLoading: false, value: "getDailyDashobardData" });
      }
    };
    return (
      <React.Fragment>
        <Header />
        <div className="main main-app p-3 p-lg-4">
          <div className="d-md-flex align-items-center justify-content-between mb-4">
            <div>
              <h4 className="main-title mb-0">Master Non Workable Dashboard</h4>
            </div>
          </div>
  
          <Card className="card-one">
            <Card.Body>
              <Table responsive>
                <thead>
                  <tr>
                    <th>Work Queue Type</th>
                    <th># Of claims</th>
                  </tr>
                </thead>
  
                <tbody>
                  {nonWorkableDashobard.map((nonWorkable, index) => (
                    <tr key={index}>
                      <td>{nonWorkable?.workQueueType}</td>
                      <td>{nonWorkable?.claimCount}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </div>
      </React.Fragment>
    );
}

export default MasterNonWorkableDashboard
