import React, { useEffect, useState } from "react";
import { Card, Button, Form } from "react-bootstrap";
import DataTable from "react-data-table-component";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import useAuth from "../../../hooks/useAuth";
import ExportExcel from "../../RuleEngine/excelExport";
import ShimmerLoader from "../../_common/ShimmerLoader";
import LoadingSpinner from "../../_common/LoadingSpinner";
import _ from "lodash";

export default function WorkedCases(props) {
  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();
  const [workedCases, setWorkedCases] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [exportData, setExportData] = useState(null);
  const [loading, setLoading] = useState({
    isLoading: false,
    value: "",
  });
  const [exportloading, setExportLoading] = useState({
    isLoading: false,
    value: "",
  });
  const formatDateTime = () => {
    const now = new Date();

    const year = String(now.getFullYear()).slice(-2);
    const month = String(now.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const day = String(now.getDate()).padStart(2, "0");
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const seconds = String(now.getSeconds()).padStart(2, "0");
    const milliseconds = String(
      Math.floor(now.getMilliseconds() / 10)
    ).padStart(2, "0"); // Convert to two-digit milliseconds

    return `${year}${month}${day}${hours}${minutes}${seconds}${milliseconds}`;
  };

  useEffect(() => {
    getWorkedCases();
  }, []);

  const getWorkedCases = async () => {
    try {
      setLoading({ isLoading: true, value: "getWorkedCases" });
      const response = await axiosPrivate.get(`Production/worked-cases`);
      setWorkedCases(response?.data?.result);
      props?.setWorkedCasesCounts(response?.data?.result?.length);
    } catch (error) {
      setLoading({ isLoading: false, value: "getWorkedCases" });
      console.error(error);
    } finally {
      setLoading({ isLoading: false, value: "getWorkedCases" });
    }
  };

  const handleExportToExcel = async () => {
    try {
      setExportLoading({
        isLoading: true,
        value: "Export",
      });
      const response = await axiosPrivate.post(
        `Production/export-cases/${auth?.id}`
      );
      const cleanedData = _.map(response.data.result, (item) =>
        _.omit(item, [
          "id",
          "status",
          "createdBy",
          "createdOn",
          "modifiedBy",
          "modifiedOn",
          "exportId",
          "assignTo",
          "caseSubmittedFormData"
        ])
      );
      setExportData(cleanedData);
      getWorkedCases();
      const ProductionLogsList = await axiosPrivate.get(
        `Production/production-log/${auth?.id}`
      );
      props?.setProductionLogs(ProductionLogsList?.data?.result);
    } catch (error) {
      console.error(error);
      setExportLoading({
        isLoading: false,
        value: "Export",
      });
    } finally {
      setExportLoading({
        isLoading: false,
        value: "Export",
      });
    }
  };

  const columns = [
    {
      name: "Visit ID",
      selector: (row) => row?.visitId,
      sortable: true,
      cell: (row) => <div>{row?.visitId}</div>,
    },
    {
      name: "Patient Name",
      selector: (row) => row?.patientName,
      sortable: true,
      cell: (row) => <div>{row?.patientName}</div>,
    },
    {
      name: "Worked By",
      selector: (row) => row?.modifiedByName,
      sortable: true,
      cell: (row) => (
        <div>
          <span>{row?.modifiedByName}</span>
        </div>
      ),
    },
    {
      name: "Worked On",
      selector: (row) => row?.modifiedOn,
      sortable: true,
      cell: (row) => (
        <div>
          {row.modifiedOn === "0001-01-01T00:00:00"
            ? "-"
            : new Date(row?.modifiedOn).toLocaleString()}
        </div>
      ),
    },
  ];

  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };

  const filteredData = workedCases.filter((item) =>
    item?.patientName?.toLowerCase().includes(searchText.toLowerCase())
  );

  return (
    <React.Fragment>
      <div className="top-header d-md-flex align-items-center justify-content-between mb-4 position-static">
        <div>
          <h4 className="fs-16 main-title mb-0">Worked Cases</h4>
        </div>

        <div className="d-flex align-items-center gap-3">
          <Button
            disabled={
              workedCases?.length === 0 ||
              (exportloading.isLoading && exportloading?.value === "Export")
            }
            variant="primary"
            className="d-flex align-items-center justify-content-center gap-1"
            onClick={handleExportToExcel}
          >
            {exportloading.isLoading && exportloading?.value === "Export" ? (
              <LoadingSpinner color="#ffffff" size={20} type={"TailSpin"} />
            ) : (
              <>
                <i className="ri-add-line fs-18 lh-1"></i>
                <span className="d-sm-inline">Export</span>
              </>
            )}
          </Button>
        </div>
      </div>

      <Card className="card-one">
        <Card.Header>
          <div className="d-flex">
            <Form.Control
              value={searchText}
              onChange={handleSearch}
              placeholder="Search..."
              className="wt-250"
            />
          </div>
        </Card.Header>
        <Card.Body className="common-data-table">
          <ExportExcel
            excelData={exportData}
            fileName={`Prod${formatDateTime()}`}
            setExportData={setExportData}
          />
          {loading.isLoading && loading.value === "getWorkedCases" ? (
            <ShimmerLoader
              colNames={columns?.map((column) => column.name)}
              colsCount={1}
            />
          ) : (
            <DataTable columns={columns} data={filteredData} pagination />
          )}
        </Card.Body>
      </Card>
    </React.Fragment>
  );
}
