import React, { useEffect, useState } from "react";
import { Button, Offcanvas, Form, Row, Col } from "react-bootstrap";
import CreateNewGroup from "./../UserGroups/create-new-group";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import Commonfield from "../../../infrastructure/core/Commonfield";
import ToastifyService from "../../_common/ToastifyService";
import useAuth from "../../../hooks/useAuth";
import LoadingSpinner from "../../_common/LoadingSpinner";

export default function CreateNewUser(props) {
  const { auth } = useAuth();
  const [userRoleList, setUserRoleList] = useState([]);
  const [teamLeadList, setTeamLeadList] = useState([]);
  const [selected, setSelected] = useState([]);
  const [formData, setFormData] = useState({
    status: Commonfield.status.inActive,
    fullName: "",
    email: "",
    role: Commonfield.defaultValue,
    teamLeadId: Commonfield.defaultValue,
  });
  const [loading, setLoading] = useState({
    isLoading: false,
    value: "",
  });
  const axiosPrivate = useAxiosPrivate();
  const getUserRoleList = async () => {
    try {
      const userRollListData = await axiosPrivate.get(`Users/user-roles`);
      setUserRoleList(userRollListData?.data);
    } catch (err) {
      console.error(err);
    }
  };
  useEffect(() => {
    if (props?.show) {
      if (props?.userAccountList) {
        setTeamLeadList(props?.userAccountList);
      }
    }
  }, [props?.show]);

  useEffect(() => {
    setFormData({
      status: Commonfield.status.inActive,
      fullName: "",
      email: "",
      role: Commonfield.defaultValue,
      teamLeadId: Commonfield.defaultValue,
    });
    getUserRoleList();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const AddUser = async () => {
    try {
      setLoading({
        isLoading: true,
        value: "CreateUser",
      });
      const userListData = await axiosPrivate.post(`Users/create-user`, {
        ...formData,
        status: formData.status
          ? Commonfield.status.active
          : Commonfield.status.inActive,
        createdBy: auth?.id,
        taskGroup: selected?.map((groups) => groups?.value),
      });
      props?.setUserAccountList(userListData?.data?.result);
      handleClearSelection();
      props?.closeFunction();
      ToastifyService.success("User Created Successfully");
    } catch (err) {
      console.error(err);
      ToastifyService.error(err?.response?.data?.message);
      setLoading({
        isLoading: false,
        value: "CreateUser",
      });
    } finally {
      setLoading({
        isLoading: false,
        value: "CreateUser",
      });
    }
  };
  console.log("formData.role", formData.role);
  const handleClearSelection = () => {
    setSelected([]);
  };
  return (
    <React.Fragment>
      <Offcanvas
        show={props.show}
        onHide={() => {
          props.closeFunction();
          handleClearSelection();
        }}
        placement="end"
        className="w-40"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="fs-16 text-dark">
            Create New User
          </Offcanvas.Title>
        </Offcanvas.Header>

        <Offcanvas.Body>
          <Row>
            <Col xl={6}>
              <div className="mb-4">
                <Form.Label>Status</Form.Label>
                <div className="d-flex align-items-center">
                  <span className="me-2 fs-sm">InActive</span>

                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      role="switch"
                      checked={formData.status}
                      name="status"
                      style={{ width: "3em", height: "1.5em" }}
                      onChange={(e) => {
                        const { name, checked } = e.target;
                        setFormData((prevData) => ({
                          ...prevData,
                          [name]: checked,
                        }));
                      }}
                    />
                  </div>

                  <span className="ms-2 fs-sm">Active</span>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col xl={6}>
              <div className="mb-4">
                <Form.Label>
                  Full Name <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Full Name"
                  name="fullName"
                  onChange={handleChange}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col xl={6}>
              <div className="mb-4">
                <Form.Label>
                  Email<span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter Email"
                  name="email"
                  onChange={handleChange}
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col xl={6}>
              <div className="mb-4">
                <Form.Label>
                  Role <span className="text-danger">*</span>
                </Form.Label>
                <Form.Select
                  name="role"
                  value={formData.role}
                  onChange={handleChange}
                >
                  <option disabled value={Commonfield.defaultValue}>
                    Select Role
                  </option>
                  {userRoleList?.map((role) => (
                    <option key={role.id} value={role.id}>
                      {role.name}
                    </option>
                  ))}
                </Form.Select>
              </div>
            </Col>
          </Row>
          {formData.role !== Commonfield.userRoles.teamLead &&
            formData.role !== Commonfield.userRoles.master &&
            formData.role !== Commonfield.defaultValue && (
              <Row>
                <Col xl={6}>
                  <div className="mb-4">
                    <Form.Label>
                      Team Leader <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Select name="teamLeadId" onChange={handleChange}>
                      <option
                        selected
                        disabled
                        value={Commonfield.defaultValue}
                      >
                        Select Team Leader
                      </option>
                      {teamLeadList?.map((role) => (
                        <option key={role.id} value={role.id}>
                          {role.fullName}
                        </option>
                      ))}
                    </Form.Select>
                  </div>
                </Col>
              </Row>
            )}
        </Offcanvas.Body>

        <div className="offcanvas-footer justify-content-start">
          <Button
            type="button"
            variant="primary"
            className="fs-14 me-2 d-flex align-items-center"
            disabled={loading.isLoading && loading?.value === "CreateUser"}
            onClick={AddUser}
          >
            {" "}
            {loading.isLoading && loading?.value === "CreateUser" ? (
              <LoadingSpinner color="#ffffff" size={20} type={"TailSpin"} />
            ) : (
              <>
                <i className="ri-add-line fs-18 lh-1 align-middle"></i>
                <span className="align-middle">Create</span>
              </>
            )}
          </Button>
        </div>
      </Offcanvas>
    </React.Fragment>
  );
}
