import React, { useState } from "react";
import { Offcanvas, Badge, Card, Form, Button } from "react-bootstrap";
import ExportExcel from "../../RuleEngine/excelExport";
import DataTable from "react-data-table-component";

export default function ProductionLogList(props) {
  const [searchText, setSearchText] = useState("");
  const [exportData, setExportData] = useState(null);
  const columns = [
    {
      name: "Visit ID",
      selector: (row) => row?.visitfid,
      sortable: true,
      cell: (row) => <div>{row?.visitfid}</div>,
    },
    {
      name: "Patient Name",
      selector: (row) => row?.patient_first_name + " "+row?.patient_last_name ,
      sortable: true,
      cell: (row) => <div>{row?.patient_first_name + " "+row?.patient_last_name }</div>,
    },
    {
      name: "Worked By",
      selector: (row) => row?.modifiedbyname,
      sortable: true,
      cell: (row) => (
        <div>
          <span>{row?.modifiedbyname}</span>
        </div>
      ),
    },
    {
      name: "Worked On",
      selector: (row) => row?.modifiedon,
      sortable: true,
      cell: (row) => (
        <div>
          {row.modifiedon === "0001-01-01T00:00:00"
            ? "-"
            : new Date(row?.modifiedon).toLocaleString()}
        </div>
      ),
    },
  ];
  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };

  const filteredData = props?.logList?.filter((item) => {
    return (
      item?.visitfid?.toString().includes(searchText.toLowerCase()) ||
      (item?.patient_first_name + " "+item?.patient_last_name )?.toLowerCase().includes(searchText.toLowerCase()) ||
      new Date(item?.createdOn)
        .toLocaleString()
        .toLowerCase()
        .includes(searchText.toLowerCase()) ||
      item?.modifiedbyname?.toLowerCase().includes(searchText.toLowerCase())
    );
  });

  return (
    <React.Fragment>
      <Offcanvas
        show={props.show}
        onHide={() =>
          props?.closeFunction({
            showLogListBool: false,
            logList: [],
            exportId: "",
          })
        }
        placement="end"
        className="w-40"
      >
        <Offcanvas.Header
          closeButton
          onClick={() =>
            props?.closeFunction({
              showLogListBool: false,
              logList: [],
              exportId: "",
            })
          }
        >
          <Offcanvas.Title className="fs-16 text-dark">
            Production Log List
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Card className="card-one">
          <Card.Header>
            <div className="d-flex align-items-center justify-content-between w-100">
              <Form.Control
                value={searchText}
                onChange={handleSearch}
                placeholder="Search..."
                className="wt-250 me-2"
              />
              <div>
                <Button
                  disabled={props?.logList.length === 0 ? true : false}
                  variant="primary"
                  className="d-flex align-items-center justify-content-center gap-1"
                  onClick={() => setExportData(props?.logList)}
                >
                  <i className="ri-add-line fs-18 lh-1"></i>
                  <span className="d-sm-inline">Export</span>
                </Button>
              </div>
            </div>
          </Card.Header>
          <Card.Body className="common-data-table">
            <ExportExcel
              excelData={exportData}
              fileName={`Prod${props?.exportId}`}
              setExportData={setExportData}
            />

            <DataTable columns={columns} data={filteredData} pagination />
          </Card.Body>
        </Card>
      </Offcanvas>
    </React.Fragment>
  );
}
