import React from "react";
import useAuth from "../../../hooks/useAuth";
import MasterDailyProductionDashboard from "./MasterDailyProductionDashboard";
import EmpDailyProductionDashboard from "./EmpDailyProductionDashboard";
import TLDailyProductionDashboard from "./TLDailyProductionDashboard";

export default function DailyProductionDashboard() {
  const { auth } = useAuth();
  return (
    <React.Fragment>
      {auth.roles[0] === "Employee" ? (
        <EmpDailyProductionDashboard />
      ) : auth.roles[0] === "Master" ? (
        <MasterDailyProductionDashboard />
      ) : (
        auth.roles[0] === "Team Lead" && <TLDailyProductionDashboard />
      )}
    </React.Fragment>
  );
}
