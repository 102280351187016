import React, { useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Image,
  Row,
  Alert,
} from "react-bootstrap";
import logo from "../../assets/img/logo/main-logo.png";
import pageSvg from "../../assets/svg/forgot_password.svg";
import { useNavigate } from "react-router-dom";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useAuth from "../../hooks/useAuth";
import LoadingSpinner from "../_common/LoadingSpinner";
import ToastifyService from "../_common/ToastifyService";
import OTPInput from "react-otp-input";

const emailRegex =
  /^(?!.*\.{2})(?!.*@.*@)[a-zA-Z0-9](?!.*@\.)(?!.*\.@)[a-zA-Z0-9._%+-]*[a-zA-Z0-9]@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(?<!\.)$/;

const passwordRegex =
  /((?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%?=*&]).{8,20})/;

export default function ForgotPasswordComponent() {
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [otpError, setOtpError] = useState("");
  const [OTP, setOTP] = useState("");
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const { setAuth } = useAuth();
  const [loading, setLoading] = useState({ loading: false, value: "" });
  const validateEmail = () => {
    if (!email) {
      setEmailError("Email is required.");
      return false;
    } else if (!emailRegex.test(email)) {
      setEmailError("Invalid email address.");
      return false;
    }
    setEmailError("");
    return true;
  };
  const validateOTP = () => {
    if (OTP.length < 6) {
      setOtpError("Please enter a valid 6-digit OTP.");
      return false;
    }
    setOtpError("");
    return true;
  };
  const validatePassword = (password) => {
    return passwordRegex.test(password);
  };
  const handleSendOTP = async () => {
    const isValid = validateEmail();
    if (isValid) {
      setLoading({ loading: true, value: "handleSendOTP" });
      try {
        const response = await axiosPrivate.post("Auth/Forget-password", {
          email,
        });
        if (response.data.status) {
          ToastifyService.success("OTP sent to your email successfully");
          setStep(2);
        } else {
          setEmailError(response.data.message || "Failed to send OTP.");
        }
      } catch (error) {
        setEmailError("Something went wrong. Please try again.");
      } finally {
        setLoading({ loading: false, value: "handleSendOTP" });
      }
    }
  };
  const handleVerifyOTP = async () => {
    const isOTPValidated = validateOTP();
    if (isOTPValidated) {
      setLoading({ loading: true, value: "handleVerifyOTP" });
      try {
        const response = await axiosPrivate.post(`Auth/otp-verify`, {
          username: email,
          otp: parseInt(OTP, 10),
        });
        if (response.data.status) {
          ToastifyService.success("OTP verified successfully");
          setStep(3);
        }
      } catch (error) {
        if (error?.response?.data?.message) {
          setOtpError(error?.response?.data?.message);
        } else {
          ToastifyService.error("Something went wrong. Please try again");
        }
      } finally {
        setLoading({ loading: false, value: "handleVerifyOTP" });
      }
    } else {
      setOtpError("Please enter a valid 6-digit OTP.");
    }
  };

  const handleSetUpPassword = async (e) => {
    e.preventDefault();
    if (!validatePassword(password)) {
      setPasswordError(
        "Password must be at least 8 characters long, contain 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character."
      );
      return;
    }
    if (password !== confirmPassword) {
      setPasswordError("Passwords do not match.");
      return;
    }
    setLoading({ loading: true, value: "handleSetUpPassword" });
    setPasswordError("");
    const data = {
      email: email,
      password: password,
      confirmPassword: confirmPassword,
    };

    try {
      const response = await axiosPrivate.post("Auth/reset-password", data);
      if (response?.status === 200) {
        ToastifyService.success("Password setup successfully");
        const {
          accessToken,
          refreshToken,
          roles,
          id,
          isResetDefaultPass,
          name,
        } = response?.data;

        const userName = email;
        setAuth({
          userName,
          password: password,
          roles,
          accessToken,
          refreshToken,
          id,
          isResetDefaultPass,
          name,
        });
        if (roles?.includes("Admin")) {
          navigate("/account/login");
        } else {
          navigate("/account/login");
        }
      } else {
        setPasswordError(response.data || "Failed to reset password.");
      }
    } catch (error) {
      setPasswordError("Something went wrong. Please try again.");
    } finally {
      setLoading({ loading: false, value: "handleSetUpPassword" });
    }
  };

  return (
    <div className="page-auth">
      <div className="header">
        <Container>
          <Image src={logo} className="wt-150" />
        </Container>
      </div>

      <div className="content">
        <Container>
          {step === 1 ? (
            <Card className="card-auth">
              <Card.Body className="text-center">
                <div className="mb-5">
                  <object
                    type="image/svg+xml"
                    data={pageSvg}
                    className="w-50"
                    aria-label="svg image"
                  ></object>
                </div>
                <Card.Title>Reset your password</Card.Title>
                <Card.Text className="mb-4">
                  Enter your email address and we will send you an OTP to verify
                  your email.
                </Card.Text>

                <Row className="g-2 justify-content-center">
                  <Col sm="8">
                    <Form.Control
                      type="text"
                      placeholder="Enter email address"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      onBlur={validateEmail}
                    />
                    {emailError && (
                      <Alert variant="danger" className="mt-2">
                        {emailError}
                      </Alert>
                    )}
                  </Col>
                  <Col sm="4">
                    <Button
                      type="button"
                      variant="primary"
                      className="btn-sign"
                      disabled={
                        loading.loading && loading?.value === "handleSendOTP"
                      }
                      onClick={handleSendOTP}
                    >
                      {loading.loading && loading?.value === "handleSendOTP" ? (
                        <LoadingSpinner
                          color="#ffffff"
                          size={30}
                          type={"TailSpin"}
                        />
                      ) : (
                        "Send OTP"
                      )}
                    </Button>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          ) : step === 2 ? (
            <Card className="card-auth">
              <Card.Body className="text-center">
                <div className="mb-5">
                  <object
                    type="image/svg+xml"
                    data={pageSvg}
                    className="w-50"
                    aria-label="svg image"
                  ></object>
                </div>
                <Card.Title>Verify OTP</Card.Title>
                <Card.Text className="mb-3">
                  Enter the OTP you received on your email.
                </Card.Text>

                <Row className="justify-content-center">
                  <Col sm="12" className="otp-form-field">
                    <OTPInput
                      value={OTP}
                      onChange={setOTP}
                      numInputs={6}
                      renderSeparator={<span>-</span>}
                      renderInput={(props) => <input {...props} />}
                    />
                    {otpError && (
                      <Alert variant="danger" className="mt-2">
                        {otpError}
                      </Alert>
                    )}
                  </Col>
                </Row>
                <Button
                  type="button"
                  variant="primary"
                  className="btn-sign mt-3"
                  disabled={
                    loading.loading && loading?.value === "handleVerifyOTP"
                  }
                  onClick={handleVerifyOTP}
                >
                  {loading.loading && loading?.value === "handleVerifyOTP" ? (
                    <LoadingSpinner
                      color="#ffffff"
                      size={30}
                      type={"TailSpin"}
                    />
                  ) : (
                    "Verify OTP"
                  )}
                </Button>
              </Card.Body>
            </Card>
          ) : (
            <Card className="card-auth">
              <Card.Body className="text-center">
                <div className="mb-4">
                  <object
                    type="image/svg+xml"
                    data={pageSvg}
                    className="w-50"
                    aria-label="svg image"
                  ></object>
                </div>
                <Card.Title>Set New Password</Card.Title>
              </Card.Body>

              <Card.Body>
                <Form>
                  <Row className="justify-content-start">
                    <Col sm="12 mb-3">
                      <Form.Label>Enter New Password:</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="Enter new password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </Col>
                    <Col sm="12 mb-2">
                      <Form.Label>Confirm Password:</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="Confirm new password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                      />
                    </Col>
                  </Row>
                  {passwordError && (
                    <Alert variant="danger" className="mt-2">
                      {passwordError}
                    </Alert>
                  )}
                  <Button
                    type="button"
                    variant="primary"
                    className="btn-sign mt-3"
                    disabled={
                      loading.loading &&
                      loading?.value === "handleSetUpPassword"
                    }
                    onClick={handleSetUpPassword}
                  >
                    {loading.loading &&
                    loading?.value === "handleSetUpPassword" ? (
                      <LoadingSpinner
                        color="#ffffff"
                        size={30}
                        type={"TailSpin"}
                      />
                    ) : (
                      "Reset Password"
                    )}
                  </Button>
                </Form>
              </Card.Body>
            </Card>
          )}
        </Container>
      </div>
    </div>
  );
}
