import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Main from "./layouts/Main";
import NotFound from "./View/_common/NotFound";
import publicRoutes from "./routes/PublicRoutes";
import {
  MasterProtectedRoutes,
  TLProtectedRoutes,
  EmployeeProtectedRoutes,
} from "./routes/ProtectedRoutes";
import "./assets/css/remixicon.css";
import "./scss/style.scss";
import { TaskProvider } from "./layouts/TaskContext";
import "reactflow/dist/style.css";
import useAuth from "./hooks/useAuth";
import LoginComponent from "./View/Account/login";
// import Unauthorized from "./View/_common/UnAuthorized";

const ROLES = {
  TeamLead: "Team Lead",
  Master: "Master",
  Employee: "Employee",
};

window.addEventListener("load", function () {
  let skinMode = localStorage.getItem("skin-mode");
  let HTMLTag = document.querySelector("html");

  if (skinMode) {
    HTMLTag.setAttribute("data-skin", skinMode);
  }
});

export default function App() {
  const { auth } = useAuth();
  const roles = auth?.roles ?? [];

  const hasRole = (allowedRoles) =>
    roles.some((role) => allowedRoles.includes(role));

  return (
    <BrowserRouter>
      <TaskProvider>
        <Routes>
          <Route path="/" element={<Main />}>
            <Route path="/account/login" element={<LoginComponent />} />
            <Route index element={<Navigate to="/account/login" replace />} />

            {hasRole([ROLES.Master]) &&
              MasterProtectedRoutes.map((route, index) => (
                <Route path={route.path} element={route.element} key={index} />
              ))}

            {hasRole([ROLES.TeamLead]) &&
              TLProtectedRoutes.map((route, index) => (
                <Route path={route.path} element={route.element} key={index} />
              ))}

            {hasRole([ROLES.Employee]) &&
              EmployeeProtectedRoutes.map((route, index) => (
                <Route path={route.path} element={route.element} key={index} />
              ))}

            {publicRoutes.map((route, index) => (
              <Route path={route.path} element={route.element} key={index} />
            ))}

            <Route
              path="*"
              element={
                roles.length > 0 ? (
                  <Navigate to="/pages/error-401" replace />
                ) : (
                  <Navigate to="/account/login" replace />
                )
              }
            />
          </Route>

          <Route path="/pages/error-401" element={<NotFound />} />
          <Route path="/not-found" element={<NotFound />} />
        </Routes>
      </TaskProvider>
    </BrowserRouter>
  );
}
