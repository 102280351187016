const defaultValue = "00000000-0000-0000-0000-000000000000";
const status = {
  active: "4e0073a0-2337-4acf-bbd7-6b265bed7f01",
  inActive: "4e0073a0-2337-4acf-bbd7-6b265bed7f04",
};
const taskRuleStatus = {
  published: "48f67ff4-6152-4a15-9294-476f09a73e77",
  draft: "48f67ff4-6152-4a15-9294-476f09a73e76",
};
const pendingQuerySts = {
  payerClosed: "48F67FF4-6152-4A15-9294-476F09A71E08",
  workLater: "48F67FF4-6152-4A15-9294-476F09A71E09",
  TLQuery: "48F67FF4-6152-4A15-9294-476F09A71E10",
};
const userRoles={
  teamLead:'42512cb8-b979-456d-b0fa-c6d39881bc7e',
  employee:'70f75eb7-8f77-4e02-a083-284f92030557',
  master:'a328fae2-91e6-4bfb-a192-de2dad8afa67'
}
const parkStatus={
  payerClosed:'48F67FF4-6152-4A15-9294-476F09A71E08',
  tlQuery:"48F67FF4-6152-4A15-9294-476F09A71E10",
  workLater:"48F67FF4-6152-4A15-9294-476F09A71E09"
}
const workQueueTypeStatus={
  available:'5e03965f-c410-46b3-8713-b5058348ab01',
  notAvailable:"5e03965f-c410-46b3-8713-b5058348ab02",
}
const inventoryStatus={
  worked:"58ad02b5-0cf2-4048-a00e-1c80afabc545",
  pending:"48f67ff4-6152-4a15-9294-476f09a73e71"
}
const documentType={
  jpeg:"b163f695-4e3e-4aba-bdb5-946432b83a23",
  pdf:"b163f695-4e3e-4aba-bdb5-946432b83a22"
}
export default {
  defaultValue,
  status,
  taskRuleStatus,
  pendingQuerySts,
  userRoles,
  parkStatus,
  workQueueTypeStatus,
  inventoryStatus,
  documentType
};
