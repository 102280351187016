import React, { useEffect, useState } from "react";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { Card, Table } from "react-bootstrap";
import Header from "../../../layouts/Header";
function EmployeeTaskDashboard() {
  const axiosPrivate = useAxiosPrivate();
  const [taskucket, settaskucket] = useState([]);
  
  useEffect(() => {
    getTaskucketData();
  }, []);
  const [loading, setLoading] = useState({
    isLoading: false,
    value: "",
  });
  const getTaskucketData = async () => {
    try {
      setLoading({ isLoading: true, value: "getMyTask" });
      const taskucketData = await axiosPrivate.get(`Dashboard/task-bucket`);
      settaskucket(taskucketData?.data?.result);
    } catch (err) {
      console.error(err);
      setLoading({ isLoading: false, value: "getMyTask" });
    } finally {
      setLoading({ isLoading: false, value: "getMyTask" });
    }
  };

  return (
    <React.Fragment>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <h4 className="main-title mb-0">Employee Task Bucket Dashboard</h4>
          </div>
        </div>

        <Card className="card-one">
          <Card.Body>
            <Table responsive>
              <thead>
                <tr>
                  <th>Task Bucket Name</th>
                  <th>Task Bucket Instructions</th>
                  <th># Of Open Cases</th>
                  <th>Ins balance</th>
                  <th>Average Weekly Inflow (Sun-Sat)</th>
                  <th>Cases Worked Last week (Sun-Sat)</th>
                  <th>Work to Inflow ratio</th>
                </tr>
              </thead>

              <tbody>
                {taskucket.map((task, index) => (
                  <tr key={index}>
                    <td>{task?.taskBucketName}</td>
                    <th>{task?.taskBucketName}</th>
                    <td>{task?.openCaseCount}</td>
                    <td>{task?.insuranceBalance}</td>
                    <td>{task?.avgWeeklyInFlow}</td>
                    <td>{task?.casesWorkedLastWeek}</td>
                    <td className="text-success">{task?.workToInFlowRatio}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </div>
    </React.Fragment>
  );
}

export default EmployeeTaskDashboard;
