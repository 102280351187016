import React from "react";
import { Card, Row, Col } from "react-bootstrap";

export default function InsuranceInformation(props) {
  return (
    <React.Fragment>
      <div className="claim-information-wrapper">
        <Card className="mb-3">
          <span className="divider-name">Insurance Information</span>
          <Card.Body className="pt-4 pb-4">
            <Row className="g-3">
              <Col xl={3} md={4} xs={6}>
                <h5 className="fs-14 mb-1">Policy Number</h5>
                <h4 className="fw-bold text-dark fs-14">
                  {props?.taskDetails?.policy_number === null ||
                  props?.taskDetails?.policy_number === ""
                    ? "-"
                    : props?.taskDetails?.policy_number}
                </h4>
              </Col>
              <Col xl={3} md={4} xs={6}>
                <h5 className="fs-14 mb-1">Payor Group</h5>
                <h4 className="fw-bold text-dark fs-14">
                  {props?.taskDetails?.payorgroup === null ||
                  props?.taskDetails?.payorgroup === ""
                    ? "-"
                    : props?.taskDetails?.payorgroup}
                </h4>
              </Col>
              <Col xl={3} md={4} xs={6}>
                <h5 className="fs-14 mb-1">Payor Name</h5>
                <h4 className="fw-bold text-dark fs-14">
                  {props?.taskDetails?.payorname === null ||
                  props?.taskDetails?.payorname === ""
                    ? "-"
                    : props?.taskDetails?.payorname}
                </h4>
              </Col>
              <Col xl={3} md={4} xs={6}>
                <h5 className="fs-14 mb-1">NETWORKTYPE</h5>
                <h4 className="fw-bold text-dark fs-14">-</h4>
              </Col>
              <Col xl={3} md={4} xs={6}>
                <h5 className="fs-14 mb-1">Home Payorid</h5>
                <h4 className="fw-bold text-dark fs-14">
                  {props?.taskDetails?.home_payorid === null ||
                  props?.taskDetails?.home_payorid === ""
                    ? "-"
                    : props?.taskDetails?.home_payorid}
                </h4>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </div>
    </React.Fragment>
  );
}
